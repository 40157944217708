import React, { useMemo } from 'react';
import './index.scss';
import { useLocation } from 'react-router-dom';

export default function SelectLanguage({language, footer}) {
  const location = useLocation().pathname;
  console.log(language);

  const links = useMemo(() => {
    let en, ru, ua;

    if (location.includes('/ua') || location.includes('/ru')) {
      const splitLocation = location.split('/').filter(item => !!item);
      splitLocation.shift(0);

      en = '/' + splitLocation.join('/');
      ru = '/' + ['ru', ...splitLocation].filter(item => !!item).join('/');
      ua = '/' + ['ua', ...splitLocation].filter(item => !!item).join('/');
    } else {
      en = location;
      ru = '/' + ['ru', ...location.split('/')].filter(item => !!item).join('/');
      ua = '/' + ['ua', ...location.split('/')].filter(item => !!item).join('/');
    }

    return {en, ua, ru}
  }, [location]);

  return (
    <div className={`select-language ${footer ? 'select-language__footer' : ''}`}>
      <a
        href={links.ru} 
        className={`disabled ${language === 'ru' ? "active" : ''}`}
      >
        RU
      </a>
      <a 
        href={links.en} 
        className={language === 'en' ? "active" : ''}
      >
        EN
      </a>
      <a
        href={links.ua} 
        className={language === 'ua' ? "active" : ''}
      >
        UA
      </a>
    </div>
  )
}

