import React from 'react';
import './Navigation.scss';
import {useLocation} from 'react-router-dom';
import {Link, Switch, Route} from 'react-router-dom';
import getLanguage from '../../utils/getLanguage';

const Navigation = ({place, setMenuShow, language}) => {
  const location = useLocation().pathname;

  const clickOnLink = () => {
    setMenuShow(false)
  }

  return (
    <nav className={`menu__nav ${place === 'footer' ? 'footer__nav' : null}`}>
      <Switch>
        <Route exact={true} path={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}`}>
          <span
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
          >
            {language === 'en' ? "Home" : language === 'ua' ? "Головна" : "Главная"}
          </span>
        </Route>
          <Link
            to={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}`}
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
            exact={true}
            onClick={clickOnLink}
          >
            {language === 'en' ? "Home" : language === 'ua' ? "Головна" : "Главная"}
          </Link>
      </Switch>
      
      <Switch>
        <Route exact={true} path={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}about`}>
          <span
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
          >
            {language === 'en' ? "About" : language === 'ua' ? "Про мене" : "Обо мне"}
          </span>
        </Route>
          <Link
            to={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}about`}
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
            exact={true}
            onClick={clickOnLink}
          >
            {language === 'en' ? "About" : language === 'ua' ? "Про мене" : "Обо мне"}
          </Link>
      </Switch>

      <Switch>
        <Route exact={true} path={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}works`}>
          <span
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
          >
            {language === 'en' ? "Works" : language === 'ua' ? "Роботи" : "Работы"}
          </span>
        </Route>
          <Link
            to={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}works`}
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
            exact={true}
            onClick={clickOnLink}
          >
            {language === 'en' ? "Works" : language === 'ua' ? "Роботи" : "Работы"}
          </Link>
      </Switch>

      <Switch>
        <Route exact={true} path={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}sociality`}>
          <span
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
          >
            {language === 'en' ? "Sociality" : language === 'ua' ? "Соціальність" : "Социальность"}
          </span>
        </Route>
          <Link
            to={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}sociality`}
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
            exact={true}
            onClick={clickOnLink}
          >
            {language === 'en' ? "Sociality" : language === 'ua' ? "Соціальність" : "Социальность"}
          </Link>
      </Switch>

      <Switch>
        <Route path={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}contacts`}>
          <span
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
          >
            {language === 'en' ? "Contacts" : language === 'ua' ? "Контакти" : "Контакты"}
          </span>
        </Route>
          <Link
            to={`/${!getLanguage(location) ? '' : `${getLanguage(location)}/`}contacts`}
            className={`menu__nav-link ${place === 'footer' ? 'footer__nav-link' : null}`}
            exact={true}
            onClick={clickOnLink}
          >
            {language === 'en' ? "Contacts" : language === 'ua' ? "Контакти" : "Контакты"}
          </Link>
      </Switch>
    </nav>
  );
}

export default React.memo(Navigation);
