import React from 'react';
import "./Footer.scss";
import {SelectLanguage} from '../SelectLanguage/index';

import {Navigation} from '../Navigation/index';
import { Social } from '../Social/index';

const Footer = ({language, allText}) => {
  return (
    <footer className='footer' id="footer">
      <div className="wrapper">
        <div className="footer__header">
          <h2 className="footer__title">{allText[`footer_title_${language}`]}</h2>
          <SelectLanguage footer={true} />
        </div>
        <div className="footer__content">
          <div className="footer__content-left">
            <span className="footer__content-name" itemProp="author">{allText[`footer_withPhoto_${language}`]}</span>
            <img className="footer__content-imageFace" src="/img/footer/YY-10.png" alt="Yova" />
          </div>
          <div className="footer__content-right">
            <div className="footer__content-right-info">
              <div className="footer__content-contacts">
                <span>{allText[`footer_mail-title_${language}`]}</span>
                {
                  allText[`footer_mail-text_${language}`].split('\n').map(item =>
                    <a key={item} href={`mailto:${item}`}>{item}</a>
                  )
                }
                <span className="second-span">{allText[`footer_call-title_${language}`]}</span>
                {
                  allText[`footer_call-text_${language}`].split('\n').map(item =>
                    <a key={item} href={`tel:${item.split(': ')[1]}`}>{item}</a>
                  )
                }

              </div>
              <div className="footer__content-sendmail">
                <span>{allText[`footer_post-title_${language}`]}</span>
                <p>{allText[`footer_post-text_${language}`]}</p>
                <div className="footer__social">
                  <Social place='footer' />
                </div>
              </div>
            </div>
            <div className="footer__menu">
              <Navigation place='footer' />
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <span>
            © {new Date().getFullYear()} Yova Yager. All rights reserved.
          </span>
          <span>
            Made with
            <img src="/img/footer/heart.svg" alt="heart" />
          </span>
          <a href="http://www.hetmanz.com" target="_blank" rel="noopener noreferrer nofollow">
            Designed by
            <img src="/img/footer/logo_HETMANZ.svg" alt="HETMANZ" />
          </a>
          <a href="https://amsg.com.ua" target="_blank" rel="noopener noreferrer nofollow">
            Developed by
            <img src="/img/footer/logo_amsg.svg" alt="AMSG" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
