import React, {useState, useEffect} from 'react';
import './SocialityPage.scss';
// import LazyLoad from 'react-lazyload';
import Masonry from 'react-masonry-css';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';

//import Components
import WorksPageNav from '../../components/WorksPageNav/WorksPageNav';
import {WorksCard} from '../../components/WorksCard/index';
// import {ButtonDecorate} from '../../components/ButtonDecorate/index';
import Preloader from '../../components/Preloader/Preloader';

//Redux actions
import {getData, getToken} from '../../store/actions';


const SocialityPage = ({screenWidth, allSocialities, setAllSocialities, language, allText}) => {
  const [filter, setFilter] = useState("VIEW ALL")
  // const [elementCount, setElementCount] = useState(4)

  useEffect(() => {
    language === 'en' ? setFilter('VIEW ALL') : setFilter('ПОКАЗАТИ ВСЕ');
    setAllSocialities(false)
    getToken('/api/login')
      .then(data => data.data['api_token'])
      .then(token =>  {
        Promise.all(
          [
            getData("/api/projects", token, 'soc', language, '', 'false')
          ])
        .then(data => {
          setAllSocialities(data[0])
        })
        .catch(err => console.log(err));
      })
  }, [])

  useEffect(() => {
    if (!allSocialities) return
    let height = $('.sociality__gallery-text').height();
    $('.sociality__gallery-table > div:nth-child(2)').css('top', -height-110)
  })

  if (!allSocialities) {
    return (
      <Preloader />
    )
  } else {

    let categories = new Set();
    allSocialities.forEach(work => categories.add(work.category_name))
    categories.delete('');
    if (categories.size === 0) {
      categories = []
    } else {
      if (language === 'en') {
        categories = ['VIEW ALL', ...categories]
      } else {
        categories = ['ПОКАЗАТИ ВСЕ', ...categories]
      }
    }

    const filteredSociality = filter === 'VIEW ALL' || filter === 'ПОКАЗАТИ ВСЕ' ? allSocialities : allSocialities.filter(item => item.category_name === filter)

    return (
      <>
        <section className="sociality">
          <div className="wrapper">
            <div className="sociality__nav">
              <h1 className="sociality__nav-title">{language === 'en' ? "Sociality" : language === 'ua' ? "Соціальність" : "Социальность"}</h1>
              <WorksPageNav setFilter={setFilter} filter={filter} categories={categories} />
            </div>
            <div className="sociality__gallery-text">
              { ReactHtmlParser(allText[`sociality_text_${language}`]) }
            </div>
            <Masonry
              breakpointCols={`${screenWidth < 700 ? 1 : 2}`}
              className="sociality__gallery-table"
              columnClassName="sociality__gallery-columns"
            >
              {filteredSociality.map((item, index) => {
                  return <WorksCard
                    key={item.id}
                    image={item.projectImage}
                    title={item.title}
                    link={item.alias}
                    location={item.city_country}
                    backgroundPici={index === 0 ? true : false}
                    area="socialities"
                  />
                })
              }
            </Masonry>
          </div>
          {/* {
            elementCount <= filteredSociality.length ?
              <LazyLoad height={elementCount >= filteredSociality.length ? 0 : 85} unmountIfInvisible={true} offset={400}>
                <div
                  onClick={() => setElementCount(elementCount + 8)}
                >
                  <ButtonDecorate
                    title={allText[`button_more_${language}`]}
                    id={'buttonSocialityPage'}
                    autoStart={true}
                  />
                </div>
              </LazyLoad>
              : null
          } */}
        </section>
      </>
    );
  }
}

export default SocialityPage;
