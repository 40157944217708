import Social from './Social';
import { connect } from 'react-redux';

const mapStateToPros = state => ({
  contactPage: state.contactPage
})

const newSocial = connect(
  mapStateToPros, 
  null
)(Social)

export { newSocial as Social };