import React, {useState, useEffect} from 'react';
import './About.scss';
import Awards_card from './Awards_card';
import $ from 'jquery';
import {getData, getToken} from '../../store/actions';

//import Components
import LazyPhotoLoad from '../../components/LazyPhotoLoad/LazyPhotoLoad';
import Preloader from '../../components/Preloader/Preloader';


const About = ({language, setAboutPage, aboutPage, allText}) => {
  const [openAwards, setOpenAwards] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  
  useEffect(() => {
    setWindowWidth($(window).width())
    if (!aboutPage) {
      getToken('/api/login')
      .then(data => data.data['api_token'])
      .then(token => getData("/api/about", token, '', language, '', '')
        .then(data => setAboutPage(data))
      )
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [windowWidth])

  const resize = () => {
    setWindowWidth($(window).width())
  }

  if (!aboutPage) {
    return (
      <Preloader />
    )
  } else {
    
    return (
      <section className="about" itemScope itemType="http://schema.org/Person">
        <meta itemProp="jobTitle" content="designer" />
        <div className="wrapper">
          <h1 className="about__title">{language === 'en' ? "About" : language === 'ua' ? "Про мене" : "Обо мне"}</h1>
          <div className="about__content">
            <div className="about__content-yova">
              <LazyPhotoLoad
                actualClass="content_photo"
                image={aboutPage[0].image_left}
                alt="Yova Yager"
                itemProp="image"
              />
              <div className="content_text">
                {
                  aboutPage[0].text_right.split('\n').map(text => {
                    if (text.includes('##')) {
                      return (
                        <p key={text} className="quote" itemProp="name">
                          {text.replace('## ', '')}
                        </p>
                      )} else if (text.includes('#')) {
                      return (
                        <p key={text} className="title">
                          {text.replace('# ', '')}
                        </p>
                      )
                    } else if (text === '') {
                      return null
                    } else {
                      return (
                        <p className="text" key={text} itemProp="description">
                          {text}
                        </p>
                      )
                    }
                  })
                }
              </div>
            </div>
            <div className="about__content-studio">
              <div className="content_text content_text-left">
                {
                  aboutPage[0].text_left.split('\n').map(text => {
                    if (text.includes('##')) {
                      return (
                        <p key={text} className="quote">
                          {text.replace('## ', '')}
                        </p>
                      )
                    } else if (text.includes('#')) {
                      return (
                        <p key={text} className="title">
                          {text.replace('# ', '')}
                        </p>
                      )
                    } else if (text === '') {
                      return null
                    } else {
                      return (
                        <p className="text" key={text} itemProp="description">
                          {text}
                        </p>
                      )
                    }
                  })
                }
              </div>
              <LazyPhotoLoad
                actualClass="content_photo"
                image={aboutPage[0].image_right}
                alt="Yova Yager studio"
                itemProp="image"
              />
            </div>
          </div>
          <div className="awards">
            <h1 className="about__title">{language === 'en' ? 'AWARDS' : language === 'ua' ? 'Нагороди' : 'Награды'}</h1>
            <div className={`awards__cards ${openAwards ? 'open' : ''}`}>
              {
                aboutPage[1].map(({title, text, image, id}) =>
                  <Awards_card
                    title_first={title}
                    title_second={text}
                    img={image}
                    key={id}
                  />)
              }
            </div>
            {
              aboutPage[1].length <= 3 
                ?
                  null 
                :
                  <button
                    className="awards__more"
                    onClick={() => setOpenAwards(!openAwards)}
                  >
                    {language === 'en' ? openAwards ? 'less awards' : 'more awards' : openAwards ? 'менше нагород' : 'більше нагород'} {openAwards === true ? '-' : '+'}
                  </button>                       
            }
          </div>
        </div>
      </section>
    )
  }
}

export default About;
