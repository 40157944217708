import React, { useEffect } from 'react';
import './App.scss';
import { Route, Switch, useLocation } from 'react-router-dom';

import {Header} from '../components/Header/index';
import {Footer} from '../components/Footer/index';
import {MainPage} from '../pages/MainPage/index';
import {WorksPage} from '../pages/WorksPage/index';
import {SocialityPage} from '../pages/SocialityPage/index';
import {About} from '../pages/About/index';
import {WorkPage} from '../pages/WorkPage/index';
import {Contact} from '../pages/Contact/index';
import Preloader from '../components/Preloader/Preloader';
import Error from '../pages/Error/Error';
import getLanguage from '../utils/getLanguage';

const App = ({isLoaded, works, allSocialities, featured, language, getAllData, changeLanguage}) => {
  const location = useLocation().pathname;

  useEffect(() => {
    changeLanguage(getLanguage(location) || 'en');

    getAllData();
  }, []);
  
  if (!isLoaded) {
    return (
      <Preloader />
    )
  } else {
    return (
      <>
        <Header />
        <Switch>
          {(featured || []).map(feature =>
            <Route exact={true} path={`/${feature.type}/${feature.alias}`} key={feature.id} component={
              () => <WorkPage id={feature.id} area={feature.type} />} />
          )}
          {(featured || []).map(feature =>
            <Route exact={true} path={`/ua/${feature.type}/${feature.alias}`} key={feature.id} component={
              () => <WorkPage id={feature.id} area={feature.type} />} />
          )}
          {(featured || []).map(feature =>
            <Route exact={true} path={`/ru/${feature.type}/${feature.alias}`} key={feature.id} component={
              () => <WorkPage id={feature.id} area={feature.type} />} />
          )}

          {(works || []).map(work =>
            <Route exact={true} path={`/works/${work.alias}`} key={work.id} component={
              () => <WorkPage id={work.id} area={work.type} />} />
          )}
          {(works || []).map(work =>
            <Route exact={true} path={`/ua/works/${work.alias}`} key={work.id} component={
              () => <WorkPage id={work.id} area={work.type} />} />
          )}
          {(works || []).map(work =>
            <Route exact={true} path={`/ru/works/${work.alias}`} key={work.id} component={
              () => <WorkPage id={work.id} area={work.type} />} />
          )}

          {(allSocialities || []).map(sociality =>
            <Route exact={true} path={`/socialities/${sociality.alias}`}  key={sociality.id} component={
              () => <WorkPage id={sociality.id} area={sociality.type} />} />
          )}
          {(allSocialities || []).map(sociality =>
            <Route exact={true} path={`/ua/socialities/${sociality.alias}`}  key={sociality.id} component={
              () => <WorkPage id={sociality.id} area={sociality.type} />} />
          )}
            {(allSocialities || []).map(sociality =>
            <Route exact={true} path={`/ru/socialities/${sociality.alias}`}  key={sociality.id} component={
              () => <WorkPage id={sociality.id} area={sociality.type} />} />
          )}

          <Route exact={true} path="/" component={MainPage} />
          <Route exact={true} path="/ua" component={MainPage} />
          <Route exact={true} path="/ru" component={MainPage} />

          <Route exact={true} path="/works" component={WorksPage} />
          <Route exact={true} path="/ua/works" component={WorksPage} />
          <Route exact={true} path="/ru/works" component={WorksPage} />

          <Route exact={true} path="/sociality" component={SocialityPage} />
          <Route exact={true} path="/ua/sociality" component={SocialityPage} />
          <Route exact={true} path="/ru/sociality" component={SocialityPage} />

          <Route exact={true} path="/about" component={About} />
          <Route exact={true} path="/ua/about" component={About} />
          <Route exact={true} path="/ru/about" component={About} />

          <Route exact={true} path="/contacts" component={Contact} />
          <Route exact={true} path="/ua/contacts" component={Contact} />
          <Route exact={true} path="/ru/contacts" component={Contact} />

          <Error />
        </Switch>
          <div className="menu__front"></div>
        <Footer />
      </>
    );
  }
}

export default App;
