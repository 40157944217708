import React from 'react';
import "./Social.scss"; 
import PropTypes from 'prop-types'

const Social = ({place, contactPage}) => {
  return ( 
    <div className={`social ${place === 'footer' ? 'social__footer' : null}`}>
      <a href={contactPage.instagram} target="_blank" rel="noopener noreferrer nofollow">
        <span>Inst</span>
      </a>
      <a href={contactPage.behance} target="_blank" rel="noopener noreferrer nofollow">
        <span>Be</span>
      </a>
      <a href={contactPage.pinterest} target="_blank" rel="noopener noreferrer nofollow">
        <span>Pnt</span>
      </a> 
    </div>
  );
}

Social.propTypes = {
  place: PropTypes.string, 
}
 
export default React.memo(Social);