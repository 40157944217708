import React from 'react'
import './Contact.scss'

//import Components
import Preloader from '../../components/Preloader/Preloader';
import {MainWaveAnimatione} from '../../components/MainWaveAnimation/index';


const Contact = ({language, contactPage}) => {

  if (!contactPage) {
    return (
      <Preloader />
    )
  } else {
    return (
      <section className="contact">
        <div className="wrapper">
        <h1 className="contact__title">{language === 'en' ? "Contacts" : language === 'ua' ? "Контакти" : "Контакты"}</h1>
        <div className="contact__info">
          <div className="contact__info-content">
            <table>
              <tr>
                <th>
                  {language === 'en' ? 'E-mail' : language === 'ua' ? 'Пошта' : 'Почта'}
                </th>
                <td>
                  <a href={`mailto:${contactPage.email}`}>{contactPage.email}</a>
                </td>
              </tr>
              <tr className="telephone">
                <th>
                  {language === 'en' ? 'Phone Numbers' : language === 'ua' ? 'Номера телефонів' : 'Номера телефонов'}
                </th>
                <td>
                  {
                    contactPage.phone.split('\n').map(tel => <a href={`tel: ${tel}`}>{tel}</a>)
                  }
                </td>
              </tr>
              <tr className="adress">
                <th>
                  {language === 'en' ? 'Address' : language === 'ua' ? 'Адреса' : 'Адрес'}
                </th>
                <td>
                  {
                    contactPage.address
                  }
                </td>
              </tr>
              <tr className='social'>
                <th>
                  {language === 'en' ? 'Social' : language === 'ua' ? 'Соціальні мережі' : 'Социальные сети'}
                </th>
                <td>
                  <a href={contactPage.instagram} target="_blank" rel="noopener noreferrer nofollow">Instagram</a>
                  <a href={contactPage.behance} target="_blank" rel="noopener noreferrer nofollow">Behance</a>
                  <a href={contactPage.pinterest} target="_blank" rel="noopener noreferrer nofollow">Pinterest</a>
                </td>
              </tr>
            </table>
            <div className="qr-code">
              <span>No stumps</span>
              <img src="/img/contact/qr.svg" alt="qr-code" />
              <span>Just QR us</span>
            </div>
          </div>
        </div>
        <MainWaveAnimatione />
      </div>
      </section>
    )
  }
}

export default Contact
